import React, {useEffect,useState} from "react";

import Navbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";

import {graphql, Link, navigate} from 'gatsby';
import CardEgzersiz from "../components/Cards/CardEgzersiz";
import {Button, Input, notification, Pagination, Popover, Select} from 'antd';
import 'antd/dist/antd.css';
import Api from "../components/common/Api";
import SecureLS from "secure-ls";
import '../styles/cardEgzersiz.css';
import '../styles/cardEgzersizRate.css';
import '../styles/index.css';
import '../styles/tailwind.css';
import SEO from "../components/seo";

export default function EgzersizKutuphanesi() {
  const [exerciseList, setExerciseList] = useState([]);
  const [pageExerciseList, setPageExerciseList] = useState([]);
  const [userExercises, setUserExercises] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [searched, setSearched] = useState(false)
  const [subjectFiltered, setSubjectFiltered] = useState(false)
  const [publicPrivateFiltered, setPublicPrivateFiltered] = useState(false)
  const [userId, setUserId] = useState()
  const [userRole, setUserRole] = useState()
  const { Search } = Input;
  const { Option } = Select;
  
  const openInNewTab = (url) => {
    if (typeof localStorage !== 'undefined'){
      let winLocation = window.location.href + url;
      const win = window.open(winLocation, '_blank');
      win.focus();
    }
  }

  const handleFilterChangePublicPrivate = (value) => {
    var filteredArray = exerciseList.filter(function(item){
      return item.public_state === value;
    });
    setPageExerciseList(filteredArray)
    setPublicPrivateFiltered(true)
    setSearched(true)
  }

  const handleFilterChangeSubject = (value) => {
    var filteredArray = exerciseList.filter(function(item){
      return item.subject_name === value;
    });
    setPageExerciseList(filteredArray)
    setSubjectFiltered(true)
    setSearched(true)
  }

  const onSearch = value => {
    if(value === ''){
      Api.getSpecificPageOnExerciseList(1)
          .then((response) => {
            setPageExerciseList(response.data.data);
          })
          .catch((e) => console.log(e));
      setSearched(false)
      setSubjectFiltered(false)
      setPublicPrivateFiltered(false)
    }else{
      setPageExerciseList(
          exerciseList.filter(function(item){
            return item.exercise_name.toLowerCase().match( value ) || item.exercise_description.toLowerCase().match( value ) ||
                item.subject_name.toLowerCase().match( value ) || item.exercise_name.toLocaleLowerCase('tr-TR').match( value ) || 
                item.exercise_description.toLocaleLowerCase('tr-TR').match( value ) || 
                item.subject_name.toLocaleLowerCase('tr-TR').match( value );
          })
      )
      setSearched(true);
      setSubjectFiltered(true)
      setPublicPrivateFiltered(true)
    }
  }
  
  const loadData = () => {
    if (typeof localStorage !== 'undefined') {
      const ls = new SecureLS({encodingType: 'aes'});
      let randomNumber = Math.floor(Math.random() * 1000000) + 1000000;
      if(ls.get('secret_key').userRole === undefined){
        let data = {
          userRole : 'guest_customer',
          userId : randomNumber,
          userName : 'guest_customer',
          userIsActivated: '1'
        }
        ls.set('secret_key', data);
        Api.guestSignUp({
          userId: randomNumber
        }).then((response) => {
        }).catch((err) => console.log(err.response.request));
        setUserId(ls.get('secret_key').userId)
        setUserRole(ls.get('secret_key').userRole)
      }
      else{
        setUserId(ls.get('secret_key').userId)
        setUserRole(ls.get('secret_key').userRole)
      }
      Api.getUserExercises({
        userId: ls.get('secret_key').userId
      }).then((response) => {
        setUserExercises(response.data.user_exercises)
      }).catch(err => console.log(err))
    }
    Api.getExerciseList()
        .then((response) => {
          setExerciseList(response.data);
        })
        .catch((e) => console.log(e));
    Api.getSpecificPageOnExerciseList(1)
        .then((response) => {
          setPageExerciseList(response.data.data);
        })
        .catch((e) => console.log(e));
    Api.getSubjectList()
        .then((response) => {
          setSubjectList(response.data);
        })
        .catch((e) => console.log(e.response.request));
  }
  
  useEffect(() => {
    loadData()
  }, [])
  
  const girisYapButonEntegre = () => {
    if(typeof localStorage !== 'undefined'){
      const ls = new SecureLS({encodingType: 'aes'});
      if(ls.get('secret_key').userRole === 'guest_customer'){
        return (
            <Link to="/auth/login">
              <button
                  className="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
              >
                <i className="fas fa-arrow-alt-circle-right">

                </i> Giriş Yap
              </button>
            </Link>
        )
      }else{
        return (<Link
            to="/profile"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 "
            }
        >
          <button
              className="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
          >
            <i className="fas fa-arrow-alt-circle-right">

            </i> Profiliniz
          </button>
        </Link>)
      }
    }else{
      return null
    }
  }
  
  const onChangePaginationNumber = (pageNumber) => {
    Api.getSpecificPageOnExerciseList(pageNumber)
        .then((response) => {
          setPageExerciseList(response.data.data);
        })
        .catch((e) => console.log(e));
  }
  
  const makeCardEgzersizImage = (exercise_name, exercise_slug) => {
    let exercise = userExercises.find(item => item.exercise_slug === exercise_slug)
    if(exercise === undefined || (parseInt(exercise.is_started) === 0 && parseInt(exercise.is_making) === 0 && parseInt(exercise.is_finished) === 0)){
      //henüz başlamamış
      return 'https://dummyimage.com/300x200/5cd0ed/000000.png&text=' + exercise_name;
    }
    else if(parseInt(exercise.is_started) === 1 && parseInt(exercise.is_making) === 1){
      //henüz bitirmemiş
      return 'https://dummyimage.com/300x200/ede734/000000.png&text=' + exercise_name;
    }
    else if(parseInt(exercise.is_making) === 1){
      //henüz bitirmemiş
      return 'https://dummyimage.com/300x200/ede734/000000.png&text=' + exercise_name;
    }
    else{
      //bitirmiş
      return 'https://dummyimage.com/300x200/34eb3d/000000.png&text= Puanınız: ' + exercise.exercise_result;
    }
  }
  
  let publicEgzersizSayisi = 0;
  for (const exercise in exerciseList) {
    if(exerciseList[exercise].public_state === '1'){
      publicEgzersizSayisi ++;
    }
  }

  let privateEgzersizSayisi = 0;
  for (const exercise in exerciseList) {
    if(exerciseList[exercise].public_state === '0'){
      privateEgzersizSayisi ++;
    }
  }

  let guestEgzersizSayisi = 0;
  for (const exercise in exerciseList) {
    if(exerciseList[exercise].public_state === '2'){
      guestEgzersizSayisi ++;
    }
  }
  return (
    <>
      <SEO
          title="Ana Sayfa"
      />
      <Navbar transparent />
      <main className="egzersiz-kutuphanesi">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            >
              
            </span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              >
                
              </polygon>
            </svg>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="mt-32">
                  <h1 className="text-white font-semibold text-4xl">
                    Egzersiz Kütüphanesi
                  </h1>
                  <h4 className="text-white font-semibold">Lütfen aşağıdaki kategorilendirme kısmından kendi üyelik tipinize göre egzersizleri görüntülemeyi unutmayınız!</h4>
                  {girisYapButonEntegre()}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300 mt-5">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full sm:w-12/12 md:w-6/12 lg:w-4/12 xl:w-3/12 lg:order-1 lg:text-right lg:self-center">
                  </div>
                  <div className="w-full sm:w-12/12 md:w-6/12 lg:w-4/12 xl:w-3/12 px-4 lg:order-2">
                    <div className="flex justify-center py-4 lg:pt-4">
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          {exerciseList.length}
                        </span>
                        <span className="text-sm text-gray-500">Toplam Egzersiz</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          {subjectList.length}
                        </span>
                        <span className="text-sm text-gray-500">Toplam Konu</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <Popover
                            content="Ücretsiz bir şekilde üye olarak katılabileceğiniz egzersiz tipidir."
                            title="Üyelere Açık Egzersiz Nedir?">
                          <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                            {publicEgzersizSayisi}
                          </span>
                          <span className="text-sm text-gray-500">Üyelere Açık Egzersiz</span>
                        </Popover>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <Popover
                            content="Online eğitimlerimizden birisini satın alan üyelerimizin katılabileceği egzersiz tipidir."
                            title="Ücretli Üyelere Açık Egzersiz Nedir?">
                          <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                            {privateEgzersizSayisi}
                          </span>
                          <span className="text-sm text-gray-500">Ücretli Üyelere Açık Egzersiz</span>
                        </Popover>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <Popover
                            content="Hiçbir şekilde üye olmadan yapabileceğiniz egzersiz tipidir."
                            title="Ziyaretçilere Açık Egzersiz Nedir?">
                          <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                            {guestEgzersizSayisi}
                          </span>
                          <span className="text-sm text-gray-500">Ziyaretçilere Açık Egzersiz</span>
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-3/12 px-4 lg:order-3 flex justify-center">
                  </div>
                </div>
                <div className="flex flex-wrap mt-3 justify-center">
                  {subjectFiltered === false ? 
                      <Select defaultValue="Egzersizleri Üyelere / Ziyaretçilere Açık Olarak Listele" style={{marginTop: '10px'}} onChange={handleFilterChangePublicPrivate}>
                        <Option value="1" key="public">Üyelere Açık Egzersizler</Option>
                        <Option value="0" key="private">Ücretli Üyelere Açık Egzersizler</Option>
                        <Option value="2" key="guest">Ziyaretçilere Açık Egzersizler</Option>
                      </Select> : <Button
                          type="danger"
                          style={{marginTop: '12px'}}
                          onClick={() => {
                            Api.getSpecificPageOnExerciseList(1)
                                .then((response) => {
                                  setPageExerciseList(response.data.data);
                                })
                                .catch((e) => console.log(e));
                            setSearched(false)
                            setSubjectFiltered(false)
                          }}>Filtreyi Kaldır!</Button>
                  }
                  <div className="relative mt-3" style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <Search
                        placeholder="Egzersiz arayın..."
                        onSearch={onSearch}
                        enterButton
                    />
                  </div>
                  {publicPrivateFiltered === false ?
                      <Select defaultValue="Egzersizleri Konuya Göre Listele" style={{marginTop: '10px'}} onChange={handleFilterChangeSubject}>
                        {subjectList.map((subject) => (
                            <Option value={subject.subject_name} key={subject.id}>{subject.subject_name}</Option>
                        ))}
                      </Select> : <Button
                          type="danger"
                          style={{marginTop: '12px'}}
                          onClick={() => {
                            Api.getSpecificPageOnExerciseList(1)
                                .then((response) => {
                                  setPageExerciseList(response.data.data);
                                })
                                .catch((e) => console.log(e));
                            setSearched(false)
                            setSubjectFiltered(false)
                            setPublicPrivateFiltered(false)
                          }}>Filtreyi Kaldır!</Button>
                  }
                </div>
                <div className="flex flex-wrap mt-6">
                  {pageExerciseList.map((exercise) => (
                      <div className="w-full sm:w-12/12 md:w-6/12 lg:w-4/12 xl:w-3/12" key={exercise.id}>
                        <button 
                            onClick={()=>{
                              const thisIsGuestErrorDesc = () => {
                                return (
                                    <>
                                      Kütüphanemizdeki üyelere özel egzersizleri sadece üyelerimiz yapabilir. Lütfen sistemimize üye olunuz!
                                      <br/>
                                      <Button onClick={() => navigate('/auth/register')}>Tıkla Ücretsiz Üye Ol!</Button>
                                    </>
                                )
                              }
                              const thisIsFreeUserErrorDesc = () => {
                                return (
                                    <>
                                      Kütüphanemizdeki ücretli üyelere özel egzersizleri sadece eğitimlerimizden birisini satın alan kişiler yapabilir!
                                      <br/>
                                      <Button onClick={() => window.location.href = 'https://fransizcaogreniyorum.net/online-fransizca-kursu/'}>Tıkla Eğitimlere Git!</Button>
                                    </>
                                )
                              }
                              const openErrorUserIsNotDefinedNotificationWithIcon = type => {
                                notification[type]({
                                  message: 'Sistemsel bir sorun var!',
                                  description:
                                      'Lütfen bir süre sonra yeniden deneyiniz!',
                                });
                              };
                              if(exercise.public_state === "0" && userRole === 'admin'){
                                // openInNewTab("/egzersiz/" + exercise.exercise_slug)
                                navigate("/egzersiz/" + exercise.exercise_slug)
                              }
                              else if(exercise.public_state === "0" && userRole !== 'paid_customer'){
                                notification.error({message: 'Bu egzersizi yapamazsınız!', description: thisIsFreeUserErrorDesc()})
                              }
                              else if(exercise.public_state === "1" && userRole === 'guest_customer'){
                                notification.error({message: 'Bu Egzersizi Göremezsiniz!', description: thisIsGuestErrorDesc()})
                              }
                              else if(exercise.public_state === "1" && userRole === 'admin'){
                                navigate("/egzersiz/" + exercise.exercise_slug)
                              }
                              else if(userRole === undefined){
                                openErrorUserIsNotDefinedNotificationWithIcon('error')
                              }
                              else{
                                if(parseInt(exercise.is_exercise_important) === 1){
                                  const close = () => {
                                    // openInNewTab("/egzersiz/" + exercise.exercise_slug)
                                    navigate("/egzersiz/" + exercise.exercise_slug)
                                  };
                                  const openNotification = () => {
                                    const key = `open${Date.now()}`;
                                    const btn = (
                                        <Button type="primary" size="small" onClick={() => close()}>
                                          Kabul Ediyorum!
                                        </Button>
                                    );
                                    notification.open({
                                      message: 'DİKKAT',
                                      description:
                                          'Bu sınava başlamadan önce en az 1 saat ayırabileceğinize ve internet bağlantınızın stabil olmasına dikkat ediniz. Düzeltme ve tekrar etme şansınız olmayacaktır.',
                                      btn,
                                      key
                                    });
                                  };
                                  openNotification()
                                }
                                else{
                                  // openInNewTab("/egzersiz/" + exercise.exercise_slug)
                                  navigate("/egzersiz/" + exercise.exercise_slug)
                                }
                              }
                            }}
                        >
                          <CardEgzersiz
                              alt={exercise.exercise_name}
                              color={() => {
                                let exerciseChoose = userExercises.find(item => item.exercise_slug === exercise.exercise_slug)
                                if(exerciseChoose === undefined || (parseInt(exerciseChoose.is_started) === 0 && parseInt(exerciseChoose.is_making) === 0 && parseInt(exerciseChoose.is_finished) === 0)){
                                  //henüz başlamamış
                                  return '#5cd0ed';
                                }
                                else if(parseInt(exerciseChoose.is_started) === 1 && parseInt(exerciseChoose.is_making) === 1){
                                  //henüz bitirmemiş
                                  return '#ede734';
                                }
                                else if(parseInt(exerciseChoose.is_making) === 1){
                                  //henüz bitirmemiş
                                  return '#ede734';
                                }
                                else{
                                  //bitirmiş
                                  return '#34eb3d';
                                  // exercise.exercise_result
                                }
                              }}
                              title={() => {
                                let exerciseChoose = userExercises.find(item => item.exercise_slug === exercise.exercise_slug)
                                if(exerciseChoose === undefined || (parseInt(exerciseChoose.is_started) === 0 && parseInt(exerciseChoose.is_making) === 0 && parseInt(exerciseChoose.is_finished) === 0)){
                                  //henüz başlamamış
                                  return exercise.exercise_name;
                                }
                                else if(parseInt(exerciseChoose.is_started) === 1 && parseInt(exerciseChoose.is_making) === 1){
                                  //henüz bitirmemiş
                                  return 'ÇÖZÜYORSUNUZ';
                                }
                                else if(parseInt(exerciseChoose.is_making) === 1){
                                  //henüz bitirmemiş
                                  return 'ÇÖZÜYORSUNUZ';
                                }
                                else{
                                  //bitirmiş
                                  return 'PUANINIZ: ' + exerciseChoose.exercise_result;
                                }
                              }}
                              description={exercise.exercise_description}
                              egzersizKonusu={exercise.subject_name}
                              publicState={exercise.public_state}
                              is_exercise_important={parseInt(exercise.is_exercise_important)}
                          />
                        </button>
                      </div>
                  ))}
                </div>
                <div className="mt-10 py-5 border-t border-gray-300 text-center">
                  {searched === false ? <Pagination defaultCurrent={1} pageSize={8} showQuickJumper onChange={onChangePaginationNumber} total={exerciseList.length} /> : 
                      <Button 
                          type="danger" 
                          onClick={() => {
                            Api.getSpecificPageOnExerciseList(1)
                                .then((response) => {
                                  setPageExerciseList(response.data.data);
                                })
                                .catch((e) => console.log(e));
                            setSearched(false)
                            setSubjectFiltered(false)
                            setPublicPrivateFiltered(false)
                          }}>Sayfalamayı Getir(Arama İptal Edilir)!</Button> }
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
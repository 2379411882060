import React, {Component} from "react";
import EgzersizRate from "../Rates/EgzersizRate";

class CardEgzersiz extends Component {
    render() {
        const cardFooterPublicState = () => {
            if(this.props.publicState === '0'){
                return(
                    <h4>Ücretli Üyelere Açık</h4>
                )
            }
            else if(this.props.publicState === '1'){
                return (
                    <h4>Üyelere Açık</h4>
                )
            }else{
                return (
                    <h4> Ziyaretçilere Açık </h4>
                )
            }
        }
        return(
            <div className="card">
                <div className="container" style={{height: '200px', position: 'relative', backgroundColor:this.props.color()}}>
                    <div className="center" style={{margin: 0, position: 'absolute', top: '50%', left: '50%', msTransform: 'translate(-50%, -50%)', transform: 'translate(-50%, -50%)'}}>
                        <p style={{color: 'black', fontWeight: 'bold', fontSize: '1rem'}}>{this.props.title()}</p>
                    </div>
                </div>
                <div className="card-body">
                    <h2>{this.props.alt}</h2>
                    <p>{this.props.description}</p>
                    <h5>{this.props.egzersizKonusu}</h5>
                    {/*<EgzersizRate*/}
                    {/*    rating={this.props.egzersizRate}*/}
                    {/*/>*/}
                </div>
                <div className="card-footer">
                    {
                        cardFooterPublicState()
                    }
                </div>
            </div>
        )
    }
}

export default CardEgzersiz;